import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';

const JobsSection = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const Jobs = () => {
  return (<>
    <Banner title='Jobs' />
    <JobsSection>
      {/* <div className='container'>
        <div className='row'>
          <div className='col'>
            <h2>Looking for Care Home Worker ?</h2>
            <p>We will Provide</p>
            <ul>
              <li>Specialist Care</li>
              <li>Overnight Care</li>
              <li>Social Care</li>
              <li>Domestic Support</li>
              <li>Personal Care</li>
              <li>Other Care</li>
            </ul>
            <p>Amen Carer Services recruits staff on a regular basis to fill care positions in Preston and the nearby region.
              we always ensure that they work hours that meet their individual needs, and
              We provide our employees with varied and rewarding work.</p>
          </div>
        </div>
      </div> */}
      {/* new text */}
      <div class="container border mt-3">
      <div class="row">
        <div class="col-md-6 p-5" style={{backgroundColor: "#eaeaea"}}>

          <h1>Looking for Care Home Work ?</h1>
          <h4>
          We specialise in providing highly qualified and experienced staff.

Our staff is very reliable and flexible in delivering high standards of health care to meet client needs because amenity carers are recruited through various stages such as interviews, face-to-face sessions, mandatory training, and DBS checks.
          </h4>
        </div>
        <div class="col-md-6 border-left py-3">
          <h1>Contact form</h1>

          <div class="form-group m-2">
            <h5 for="name">Name</h5>
            <input type="text" class="form-control" id="name" placeholder="Enter name"/>
          </div>

          <div class="form-group m-2">
            <h5 for="email">Email</h5>
            <input type="email" class="form-control" id="email" placeholder="Enter email"/>
          </div>

          <div class="form-group m-2">
            <h5 for="number">Number</h5>
            <input type="number" class="form-control" id="number" placeholder="Enter Your Number"/>
          </div>

          <div class="form-group m-2">
            <h5 for="message">Message</h5>
            <textarea class="form-control" id="message" rows="3"></textarea>
          </div>

          <button class="btn btn-primary m-4" onclick="sendMail()">Submit</button>

        </div>
      </div>
    </div>
    </JobsSection>
  </>)
}

export default Jobs