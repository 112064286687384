import React, {useEffect} from 'react'
import recruitmentImage from '../images/help-2.jpg';
import { Button } from './Button';
import { animateScroll as scroll } from 'react-scroll';
import styled from 'styled-components';

import Aos from "aos";
import "aos/dist/aos.css";

const RecruitmentSection = styled.div`
  padding: 4rem 0;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const RecruitmentText = styled.div`
  padding: 10px;
`;

const RecruitmentImage = styled.img`
  padding: 20px;
`;
const Recruitment = () => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  }

  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);


  return (
    <RecruitmentSection>
    <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div data-aos="fade-left" className="about-image">
                <RecruitmentImage className='img-fluid' src={recruitmentImage} alt='' />
              </div>
            </div>
            <div data-aos="fade-right" className="col-md-6 col-sm-12">
              <div class="align-self-center pt-2">
                <RecruitmentText className='recruitment-text'>
                  <h2 className='py-4'><u>RECRUITMENT</u></h2>
                  <p>We hire staff according to a unique procedure. It will go through a series of steps, including an interview, document verification, mandatory training, a DBS check, and a core ability assessment to determine their level of care knowledge.</p>

    
                    <div className='pt-4'>
                      <Button to='/jobs' onClick={scrollToTop} primary='true' css={`max-width: 160px;`}>Looking For Job ?</Button>
                    </div>                  
                </RecruitmentText>
              </div>
            </div>
          </div>
        </div>
        </RecruitmentSection>
  )
}


export default Recruitment