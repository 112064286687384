import React, {useState} from "react";
import { Routes, Route } from "react-router-dom";
import Dropdown from "./components/Dropdown";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import AboutPage from "./pages/AboutPage";
import JobsPage from "./pages/JobsPage";
import ServicesPage from "./pages/ServicesPage";
//import { menuData } from "./data/MenuData";
import GlobalStyle from "./globalStyles";
import Contact from "./components/Contact";
import ContactPage from "./pages/ContactPage";
import FAQsPage from "./pages/FAQsPage";
import TandCPage from "./pages/TandCPage";
// import GoTopButton from "./components/GoTopButton";
import FloatingWhatsapp from "./components/FloatingWhatsapp";


function App() {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
    
      <GlobalStyle />
      <Navbar toggle={toggle} />
      {/* <GoTopButton /> */}
      <FloatingWhatsapp />
      
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Routes>
        <Route path='' exact element={<Home />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='jobs' element={<JobsPage />} />
        <Route path='services' element={<ServicesPage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='faq' element={<FAQsPage />} />
        <Route path='tandc' element={<TandCPage />} />
      </Routes>  
      <Contact /> 
    </>
  );
}

export default App;
