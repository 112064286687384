import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import AmenCarerLogo from '../images/WhatsappLogo.jpeg';




const FloatingWhatsapp = () => {
  return (
    <div>
        <FloatingWhatsApp phoneNumber='+44 7776 934 190' accountName='Amen Carers' avatar={AmenCarerLogo} />
    </div>
  )
}

export default FloatingWhatsapp