import React from 'react';
import Hero from './Hero';
import { SliderData } from "../data/SliderData";
import About from './About';
// import Presentation from './Presentation';
import Services from './Services';
import Mission from './Mission';
// import Staff from './Staff';
import Recruitment from './Recruitment';


export const Home = () => {
  return (
    <>
    <Hero slides={SliderData} />
    <About /> 
    <Services />
    <Mission />
    
    {/* <Presentation /> */}
    {/* <Staff /> */}
    <Recruitment />
    
    </>
  )
}

export default Home