import React, {useEffect} from 'react'
import styled from 'styled-components'
// import { missionData } from '../data/ServicesData';
import staffImage from '../images/staff.jpg';
import { Button } from './Button';
import { animateScroll as scroll } from 'react-scroll';

import Aos from "aos";
import "aos/dist/aos.css";


const MissionSection = styled.div`
    background-color: #eaeaea;
    padding-top: 4rem;
    padding-bottom: 4rem;
`;

const Heading2 = styled.h2`
    font-size: 24px;
`;

// const MissionImage = styled.img`
//     height: 100px;
// `;

const MissionBtn = styled.div`
    margin-top: 1rem;
    text-align: center;
`;
const StaffList = styled.ul`
    
`;
const StaffItem = styled.li`
list-style: none;
border-left: 3px solid #2d3a53;
padding-left: 5px;
margin-top: 5px;
margin-bottom: 5px;
transition: all 0.5s ease;

    &: hover{
        font-size: 24px;
    }
`;

const StaffImage = styled.img`
`;

const Mission = () => {

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    useEffect(() => {
        Aos.init({duration: 2000});
      }, []);

    return (
        <MissionSection>
            <div className="container">
                
                <div className="row">
                    <div className="col-md-8">
                    <h2 data-aos="fade-left" className='pb-3 text-center'><b><u>OUR STAFF</u></b></h2>
                        <Heading2 data-aos="fade-left" className='py-3 text-center'>Our Aim to deliver high standards of a care to elderly people to
                            make their life easier and healthier.
                            We have supply staff who are highly trained and experienced to deliver quality care</Heading2>
                        {/* <div className="row">
                    {missionData.map((item, index) => (
                        <div className="col" key={index}>
                            <MissionImage src={item.image} alt="mission" className='img-fluid' height={50} />
                            <p className='py-3'>{item.title}</p>
                        </div>
                    ))}
                </div> */}
                        <h6 className='p-2'>we can able to provide highly professional staff like</h6>
                        <StaffList>
                            <StaffItem>Healthcare Assistance</StaffItem>
                            <StaffItem>Senior Carers</StaffItem>
                            <StaffItem>Support Workers</StaffItem>
                            <StaffItem>Nurses</StaffItem>
                        </StaffList>
                        <MissionBtn data-aos="fade-up">
                            <Button to='jobs' onClick={scrollToTop} primary='true'>Looking for Staff ?</Button>
                        </MissionBtn>
                    </div>
                    <div className="col-md-4">
                    <div className="about-image">
                        <StaffImage className='img-fluid' src={staffImage} alt='staff' />
                    </div>
                    </div>
                </div>
            </div>
        </MissionSection>
    )
}

export default Mission