// import React, { useEffect, useState } from 'react'
import React, {useEffect} from 'react'
import styled, { css } from 'styled-components/macro';
import AmenCarerLogo from '../images/updated-logo.png';
import { Link } from 'react-router-dom';
import { menuData } from '../data/MenuData';
// import { Button } from './Button';
import { MdCall } from 'react-icons/md';
// import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs';
import { CgMenuRightAlt } from 'react-icons/cg';
import { animateScroll as scroll } from 'react-scroll';
// import CallBtn from '../images/call.gif'
import FaceBookBtn from '../images/facebook-1.gif'
import InstaGramBtn from '../images/instagram.gif'
import LinkedInBtn from '../images/linkedin.gif'

import Aos from "aos";
import "aos/dist/aos.css";

const Nav = styled.nav`
    //background: ${({ scrollNav }) => (scrollNav ? '#fff' : 'transparent')};
    background: #fff;
    height: 90px;
    display:flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
    // box-shadow: ${({ scrollNav }) => (scrollNav ? '5px 10px 30px rgba(45, 48, 83, 0.157)' : '')};
    box-shadow: 5px 10px 30px rgba(45, 48, 83, 0.157);
`;

const NavLink = css`
    display: flex;
    align-items: center;
    padding: 1.1rem 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
`;

const Logo = styled(Link)`
    ${NavLink}
    /*font-style: italic;*/

    @media screen and (max-width: 768px) {
        padding-left: -60px;
    }
`;

const MenuBars = styled(CgMenuRightAlt)`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        color: #2d3a53;
        background-size: contain;
        height: 40px;
        width: 40px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-50%, 25%);
    }
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    margin-right: -48px;
    

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const NavMenuLinks = styled(Link)`
    //color: ${({ scrollNav }) => (scrollNav ? '#2d3a53' : '#fff')};
    color: #2d3a53;
    text-transform: uppercase;
    padding: 30px 25px !important;
    ${NavLink}

    &:hover{
        color: #fff;
        background: #000d1a;
    }
`;

const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const SocialIcons = styled.div`
    color: #2d3a53;
    padding: 1rem;

    i {
        padding: 0.5rem;   
        font-size: 18px; 
    }
`;
const PhoneNum = styled.i`
    margin-right: 25px;
    text-decoration: none;
    color: #2d3a53;
`;
const PhoneIcon = styled(MdCall)`
  margin-right: 0.5rem;
  font-size: 18px;
`;

const Navbar = ({ toggle }) => {

    // Navbar BG color
    // const [scrollNav, setScrollNav] = useState(false);
    // const changeNavBg = () => {
    //     if (window.scrollY >= 50) {
    //         setScrollNav(true);
    //     } else {
    //         setScrollNav(false);
    //     }
    // };
    // useEffect(() => {
    //     window.addEventListener('scroll', changeNavBg);
    // }, []);

    // scroll to bottom
    // const scrollToBottom = () => {
    //     scroll.scrollToBottom();
    // }

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    useEffect(() => {
        Aos.init({duration: 2000});
      }, []);

    return (
        // <Nav scrollNav={scrollNav}></Nav>
        <Nav>

            <Logo data-aos="fade-down" to="" onClick={scrollToTop}>
                <img src={AmenCarerLogo} alt='logo' height={150} />
            </Logo>
            <MenuBars onClick={toggle} />
            <NavMenu>
                {menuData.map((item, index) => (
                    <NavMenuLinks onClick={scrollToTop} to={item.link} key={index}>{item.title}</NavMenuLinks>
                ))}

            </NavMenu>
            <NavBtn>
                {/* <Button onClick={scrollToBottom} round='true' big='true' primary='true'>Contact us</Button> */}
                <SocialIcons>
                    <PhoneNum>
                        <a href='tel:+0 194 274 9445'>
                            <PhoneIcon />0 194 274 9445
                        </a>
                    </PhoneNum>
                    <i to='/'><img src={FaceBookBtn} width="26"alt="" /></i>
                    <i to='/'><img src={InstaGramBtn} width="25"alt="" /></i>
                    <i to='/'><img src={LinkedInBtn} width="25"alt="" /></i>
                </SocialIcons>
            </NavBtn>
        </Nav>
    )
}

export default Navbar