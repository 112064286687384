import React from 'react';
import styled from 'styled-components';
import { faqsData } from '../data/ServicesData';
import Banner from '../components/Banner';

const FAQSection = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

const ListItemAns = styled.li`
  list-style: none;
  color: grey;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
`;

const FAQsPage = () => {
  return (
    <div>
      <Banner title='Frequently Asked Questions' />
      <FAQSection>
        <div class="container">
          <h2 className='py-3'>FAQs</h2>
          <ul>
            {faqsData.map((item, index) => (
              <li key={index}>{item.que} <br />
                <ListItemAns>{item.ans}</ListItemAns></li>
            ))}
          </ul>
        </div>
      </FAQSection>
    </div>
  )
}

export default FAQsPage