import ImageOne from '../images/hero-bg.jpg';
// import ImageTwo from '../images/header2.jpg';

export const SliderData = [
    {
        title: '"We will Bring Care to your life"',
        tagLine1: 'We always ensure Quality of care to people life and',
        tagLine2: ' that is our main priority and desire.',
        path: '/jobs',
        label: 'Looking For Staff',
        image: ImageOne,
        alt: 'House'
    },
    // {
    //     title: 'Luxury villa in Bali, Indonesia',
    //     price: '$4,280,000',
    //     path: '/homes',
    //     label: 'View Home',
    //     image: ImageTwo,
    //     alt: 'House'
    // }
];