import React from 'react'
import styled from 'styled-components';
import Banner from '../components/Banner';

const TcSection = styled.div``;

const TandCList = styled.ol`
  li {
    padding-bottom: 5px;
  }
`;

const TandCPage = () => {
  return (
    <div>
      <Banner title='Terms & Conditions' />
      <TcSection>
        <div class="container mb-5">
          <h4 className='mt-5 mb-4'>TERMS AND CONDITIONS FOR THE SUPPLY OF TEMPORARY WORKERS</h4>
          <TandCList>
            <li>In these terms and conditions, the following expressions shall have the following meanings:
              “Amen carers Ltd.” Business Address: 320 Blackpool Road,Preston, Lancashire,PR2 3AA,UK
              Temporary Workers” - Any health or social care worker introduced and/or supplied by Amen carers Ltd. to render services to the Client
              “The client”,    The person, firm or corporate body together with any subsidiary or associated person, firm or corporate body (as the case may be) to whom the Temporary Worker is introduced and/or supplied
              “Interest” - Interest calculated at the daily rate of 2% per calendar month. This interest refers to late payments,
              where the invoice has not been paid on time, as per this contract (section 4). A 2% of the total sum stipulated on the invoice will be charged for each overdue day. A new invoice will be issued to reflect the added interest.
              The period during which the Temporary Worker is introduced and/or supplied by Amen carers ltd. to render services to the Client.</li>
            <li>These terms and conditions are effective from 15th of January 2023 and supersede all previous terms and conditions.
              All Temporary Workers are introduced and/or supplied by Amen carers Ltd. to the Client upon these terms and conditions.</li>
            <li>No variations of these terms is valid or shall be binding by Amen carers Ltd. unless confirmed in writing with the authority of a director.</li>
            <li>Amen carers Ltd. invoices are payable within 14 days of the date of the invoice.</li>
            <li>Amen carers Ltd. reserves the right to charge Interest on all invoices raised from the due date of payment until the date payment is actually received by Amen carers  Ltd.</li>
            <li>Amen carers Ltd. may, without prejudice to its other rights, suspend the services of a Temporary Worker if a client is late with any payment.</li>
            <li>Whilst every effort is made by Amen carers Ltd. to give satisfaction to the Client by ensuring reasonable standards of skills, integrity and reliability from Temporary Workers and further provide them in accordance with the Clients booking details, Amen carers Ltd is not liable for any loss, expense, damage, or delays arising from any failure to provide any Temporary Worker for all or part of the period of booking or from the negligence, dishonesty, misconduct or lack of skill of the Temporary Worker. For the avoidance of doubt, Amen carers Ltd  does not exclude liability for death or personal injury arising from its own negligence.</li>
            <li>By asking Amen carers Ltd. to introduce and /or supply a Temporary Worker the Client is deemed to have accepted these terms of business. For the purpose of this clause the word “introduce” shall be deemed to include without limitation the provision by Amen carers Ltd. directly or indirectly of any details (whether provided in writing or verbally) of a Temporary Worker to a Client.</li>
            <li>The charge made by Amen carers Ltd. for the Workers services will be in accordance with the scale of charges advised to the Client at the time of booking. The charge includes the Temporary Workers pay plus WTD plus Employers National Insurance contribution plus Commission. Expenses will be charged and invoiced as agreed with the Client.</li>
            <li>VAT is payable, where applicable, at the prevailing rate.</li>
            <li>
              <ul>
                <li>The Client undertakes to supervise the Temporary Worker to ensure the Client satisfaction with the Temporary Workers standards of workmanship. If the Client reasonably considers that the services of the Temporary Worker are unsatisfactory, the Client may terminate the Assignment by instructing the Temporary Worker to leave the Assignment immediately and notifying Amen carers Ltd. immediately or by directing Amen carers Ltd. to remove the Temporary Worker. Amen carers Ltd. may, in such circumstances reduce or cancel the charges for the time worked by that Temporary Worker, provided that the Assignment terminates before the end of the first day of the Assignment or within two hours of the start of the Assignment if the Temporary Worker is to be used for less than 12 hours in any one week.</li>
                <li>A Client may terminate an Assignment only upon giving 24 hours’ notice in writing to Amen carers Ltd. failing which it shall be entitled to terminate an Assignment but it may do so only upon payment of a sum equal to eight times the hourly rate for the Temporary Worker.</li>
              </ul>
            </li>
            <li>The Client is responsible for ensuring that the Temporary Worker’s ability, qualifications and skills are adequate to operate any equipment, machinery and/or vehicles that the Temporary Worker may be called upon to operate and the Client will be solely responsible for any liability whatsoever which may arise from the Temporary Worker’s performance of his/her duties.</li>
            <li>Temporary Workers introduced and/or supplied by Amen carers Ltd. are engaged under contracts for services. They are not the employees of Amen carers Ltd. but are deemed to be under the supervision, direction and control of the Client from the time they report to take up duties and for the duration of the Assignment.The Client agrees to be responsible for all acts, errors or omissions of the Temporary Worker, whether wilful,negligent or otherwise as though the Temporary Worker was on the payroll of the Client. The Client will also comply
              in all respects with all statutes including, for the avoidance of doubt, the Working Time Regulations and health and safety legislation including, in particular, the provision of Employers and Public Liability Insurance cover for the Temporary Worker during all Assignments.</li>
            <li>The Client shall indemnify and keep indemnified Amen carers Ltd. against any costs, claims or liabilities incurred by Amen carers Ltd. arising out of any non-compliance by the Client of clause 13 and/or as a result of any breach of these terms and conditions by the Client.</li>
            <li>The Client shall notify Amen carers Ltd. immediately upon engaging or otherwise making use of a Temporary Worker.
              Amen Carers Ltd. or the Client refers the Temporary Worker to an associated or subsidiary company of the Client or to any third party who so employs or makes use of the Temporary Worker the Client will be liable to pay Amen carers Ltd. an introduction fee of 200 x the Workers hourly charge rate. Amen carers Ltd. will be entitled to invoice the Client for such fee which invoice shall be payable in accordance with Amen carers Ltd. Terms of
              payment contained in these terms and conditions. Alternatively, the Client may elect by notice of 4 weeks to extend a Temporary Workers Assignment by 6 weeks on no less favourable terms after which time the Temporary Worker may be employed directly without further charge.</li>
            <li>The Client will sign the timesheet and such signature or other formal confirmation of completion of Assignment shall be deemed conclusive evidence:
              <ul>
                <li>that the Client is satisfied with the work done by the Temporary Worker concerned and</li>
                <li>that the Client will pay the charges for the hours on the timesheet in full without dispute or deduction.</li>
              </ul>
            </li>
            <li>Failure by the Client to sign any timesheet shall not preclude charging by Agency Care Staff Ltd. in full or the time actually worked by the Temporary Worker in accordance with these terms and conditions.</li>
            <li>Unless and until Amen carers Ltd. are notified of dissatisfaction as provided in Clause 11 the Client shall be deemed to be fully satisfied.</li>
            <li>Because of the nature of Amen carers Ltd‘s business the Client acknowledges and agrees that it is not possible for Amen carers Ltd. to give any warranty as to the suitability of a Temporary Worker and shortcomings in the performance of his/her duties by the Temporary Worker shall not entitle the Client to withhold or delay (in part or in full) payment of Amen carers Ltd‘s invoices (save and except where the Client has
              given notice in accordance with Clause 11 of these terms and conditions).</li>
            <li>In any event, the liability of Amen carers Ltd. in contract and/or tort in respect of the introduction and/or supply of any Temporary Worker to the Client shall be limited to the profit element of Amen carers Ltd‘s charges to the Client in respect of the Temporary Worker. Service Users in their own home will be exempt from clauses 12, 13 and 14 of these Terms and Conditions.</li>
          </TandCList>

          <p>Client’s Name Client’s Role</p>
          <p>Client’s Signature Date:</p>
          <p>Agency Representative’s Name Role</p>
          <p>Agency Representative’s Signature Date:</p>
        </div>
      </TcSection>
    </div>
  )
}

export default TandCPage