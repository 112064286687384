import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }

    html, body{
        overflow-x: hidden;
    }
`;

// theme colors
// blue
// blue-medium: #195f83;
// blue-highlight: #146792;
// blue-solid: #1b557b;

// shade-dark: #2d3a53;
// shade-grey: #283e5d;

export default GlobalStyle;