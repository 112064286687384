import React from 'react'
import styled from 'styled-components'

const MapDiv = styled.div`
    padding-top: 1rem;
    margin-bottom: 3rem;
`;
const ContactInfo = styled.div`
    a {
        font-size: 1.3rem;
        font-weight: 500;
        color: #1b557b;
        text-decoration: none;
    }
`;

const MapLocation = () => {
    return (
        <>
        <ContactInfo>
                <div className="container">
                    <div className="mt-5 row">
                        <div className="col-md-6 col-sm-12 pb-4">
                            <h2 className='pb-2'><u>Call Us..</u></h2>                            
                            <a href='tel:+0 194 274 9445'>0 194 274 9445</a><br/>
                            <a href='tel:+44 777 693 4190'>0 777 693 4190</a>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h2 className='pb-2'><u>Mail Us..</u></h2>                               
                            <a href='mailto:info@amencarers.co.uk'>info@amencarers.co.uk</a><br/>
                            <a href='mailto:recruitment@amencarers.co.uk'>recruitment@amencarers.co.uk</a>                         
                        </div>
                    </div>
                </div>
            </ContactInfo>
            <MapDiv>
                <div className="container">
                    <h2 className='pb-3'><u>Find us..</u></h2>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2357.862242028714!2d-2.7119957!3d53.7741397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b720b0781b541%3A0xb6ae3878a692135a!2s320%20Blackpool%20Rd%2C%20Fulwood%2C%20Preston%20PR2%203AA%2C%20UK!5e0!3m2!1sen!2sin!4v1672140483069!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        title='map-location'
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </MapDiv>
            
        </>
    )
}

export default MapLocation