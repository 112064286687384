import React from 'react';
import styled from 'styled-components';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
import { menuData } from '../data/MenuData';
import { FaTimes } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';

import { MdCall } from 'react-icons/md';
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs';

const DropdownContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #146792;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

const CloseIcon = styled(FaTimes)`
    color: #000d1a;
`;

const DropdownWrapper = styled.div``;

const DropdownMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
    margin-bottom: 4rem;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(4, 60px);
    }
`;

const DropdownLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #000d1a;
    }
`;

const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
`;

const SocialIcons = styled.div`
    color: #fff;
    padding: 1rem;

    i {
        padding: 1rem;    
    }
`;
const PhoneIcon = styled(MdCall)`
  margin-right: 0.5rem;
`;


const PhoneNum = styled.p`
    margin-right: 25px;

`;


const Dropdown = ({ isOpen, toggle }) => {

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    return (
        <DropdownContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <DropdownWrapper>
                <DropdownMenu>
                    {menuData.map((item, index) => (
                        <DropdownLink onClick={scrollToTop} to={item.link} key={index}>
                            {item.title}
                        </DropdownLink>
                    ))}
                </DropdownMenu>
                <BtnWrap>
                    {/* <Button primary="true" onClick={scrollToTop} round="true" big="true" to="/contact">
                    Contact Us
                </Button> */}

                    <SocialIcons>                        
                            <PhoneNum to='/'><PhoneIcon />+44 194 274 9445</PhoneNum><br /><br />
                        <div>
                        <i to='/'><BsFacebook /></i>
                        <i to='/'><BsInstagram /></i>
                        <i to='/'><BsLinkedin /></i>

                        </div>
                        
                    </SocialIcons>
                </BtnWrap>
            </DropdownWrapper>
        </DropdownContainer>
    )
}

export default Dropdown