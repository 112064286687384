import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import aboutImage from '../images/help.jpg';
import { animateScroll as scroll } from 'react-scroll';
// import * as Animatable from 'react-native-animatable';
// MyCustomComponent = Animatable.createAnimatableComponent(MyCustomComponent);

import Aos from "aos";
import "aos/dist/aos.css";

const AboutSection = styled.div`
  background-color: #eaeaea;
  padding: 4rem 0;
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

// const AboutImage = React.lazy();

const AboutImage = styled.img`
  padding: 20px;
`;

const AboutText = styled.div`
  padding: 10px;
`;

const About = (props) => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  }

  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);

  return (
    <>
      <AboutSection>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div data-aos="fade-right" className="about-image">
                <AboutImage className='img-fluid' src={aboutImage} alt='' />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div data-aos="fade-left" class="align-self-center pt-2">
                <AboutText className='about-text'>
                {/* <Animatable.Text animation="zoomInUp">Get to know more ABOUT us</Animatable.Text> */}
                  <h2 className='py-4'><u>Get to know more ABOUT us</u></h2>
                  <p>Amen Carers is one of the Trusted agencies in the United Kingdom, 
                    Quality always matters to us. It started in the year 2022. We always deal 
                    with clients with honesty and dignity, we provide a large number of healthcare assistants, 
                    support workers, and nurses to care homes. Our staff is fully trained and experienced. 
                    </p>

                    <p>They were the best in the market who delivers a high standard of care assistance to the 
                    clients we mainly focused on elderly people and make their lives healthier and easier.</p>
                    <div className='pt-4'>
                      <Button to='/jobs' onClick={scrollToTop} primary='true' css={`max-width: 160px;`}>Looking For Staff</Button>
                    </div>                  
                </AboutText>
              </div>
            </div>
          </div>
        </div>
      </AboutSection>
    </>

  )
}

export default About