import React from 'react'
import Banner from '../components/Banner'
import Services from '../components/Services'

const ServicesPage = () => {
  return (
    <>
    <Banner title='Services' />
    <Services />
    </>
  )
}

export default ServicesPage