import ServiceImgOne from '../images/feature_1.jpg';
import ServiceImgTwo from '../images/feature_2.jpg';
import ServiceImgThree from '../images/feature_4.jpg';
import ServiceImgFour from '../images/feature_3.jpg';
import quality from '../images/quality.png';
import elder from '../images/elderly.png';
import dbs from '../images/dbs.png';

export const servicesData = [
    {
        title: 'Care / Nursing Homes',
        info: 'We primarily focus on providing staff to care homes and nursing homes, our staff always maintains honesty and dignity when dealing with care home residents and communicates effectively with residents in order to meet their needs.',
        image: ServiceImgOne,
        alt: 'care',
        link: 'View More'
    },
    {
        title: 'Support Shifts',
        info: 'Our staff is qualified to assist the clients and is trained to maintain the surroundings very well and clean because we always give priority to the safety of our clients.',
        image: ServiceImgTwo,
        alt: 'support',
        link: 'View More'
    },
    {
        title: 'End of life',
        info: 'Our staff is highly trained in caring for people nearing the end of their lives, and they frequently observe clients to determine their condition. ',
        image: ServiceImgThree,
        alt: 'end',
        link: 'View More'
    },

    {
        title: 'Elderly Care',
        info: 'Eldercare professionals are experts at caring for the elderly, they are trained to deal with age-related issues such as dementia and others.',
        image: ServiceImgFour,
        alt: 'support',
        link: 'View More'
    },
   
];

export const missionData = [
    {
        title: 'Highly experienced and trained nursers, senior carers and care assistants',
        image: elder,
    },
    {
        title: 'Fully NHS complaints & DBS checked',
        image: dbs,
    },
    {
        title: 'Empathetic & Professional',
        image: quality,
    }
];

export const faqsData = [
    {
        que: 'What is Amen Carers?',
        ans: 'We are',
    },
    {
        que: 'What do we work for?',
        ans: 'We are',
    },
    {
        que: 'Where are we located?',
        ans: 'We are located at 320 Blackpool Rd, Fulwood,Preston, United Kingdom, PR2 3AA',
    },
    {
        que: 'How you can help us?',
        ans: 'We are',
    },
    {
        que: 'How to contact us?',
        ans: 'You can contact us via email: info@amencarers.co.uk or phone: +0 194 274 9445',
    }
];