import React, {useEffect} from 'react';
import styled from 'styled-components/macro';
import { Button } from './Button';
import { IoMdArrowRoundDown } from 'react-icons/io';
// import {IoArrowForward, IoArrowBack} from 'react-icons/io5';
import { animateScroll as scroll } from 'react-scroll';
// import HeadingTextImg from '../images/amen-wecare2.png';
// import { motion } from "framer-motion";

import Aos from "aos";
import "aos/dist/aos.css";

const HeroSection = styled.section`
  height: 100vh;
  max-height: 1100px;
  position: relative;
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const HeroSlide = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
`;

const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before{
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(
      0deg, 
      rgba(0,0,0,0.2) 0%,
      rgba(0,0,0,0.2) 50%, 
      rgba(0,0,0,0.6) 100%
    );
  }
`;

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: calc(100% - 100px);
  color: #fff;

  h1 {
    font-size: clamp(1rem, 8vw, 3.5rem);
    font-weight: 600;
    text-shadow: -1px 1px 0 #fff,
                          1px 1px 0 #fff,
                         1px -1px 0 #fff,
                        -1px -1px 0 #fff !important;
    /*text-transform: uppercase;*/
    
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);
    color: #146792;
    margin-bottom: 0.8rem;
  }

  p {
    padding-left: 20px;
    font-size: clamp(1rem, 8vw, 2rem);
    margin-bottom: 2.5rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
`;

const HeroBtn = styled.div`  
  align-items: center;
`;

const Arrow = styled(IoMdArrowRoundDown)`
  margin-left: 0.5rem;
`;


const Hero = ({ slides }) => {

  const scrollMore = () => {
    scroll.scrollMore(660);
  }

  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);

  return (
    <HeroSection>
      <HeroWrapper>
        {slides.map((slide, index) => {
          return (
            <HeroSlide key={index}>
              <HeroSlider>
                <HeroImage src={slide.image} alt={slide.alt} />
                <HeroContent>
                  {/* <h1>{slide.title}</h1> */}
                  <h1 data-aos="fade-right">{slide.title}</h1>
                  {/* <img src={HeadingTextImg} alt="hero-img" height={100} width={500} /> */}
                  <p data-aos="fade-right">{slide.tagLine1} <br />
                  {slide.tagLine2}</p>
                  <HeroBtn data-aos="fade-right">
                    <Button onClick={scrollMore} primary='true' big='true' css={`max-width: 160px;`}>{slide.label}<Arrow /></Button>
                  </HeroBtn>
                </HeroContent>
              </HeroSlider>
            </HeroSlide>
          )
        })}
      </HeroWrapper>
    </HeroSection>
  )
}

export default Hero;