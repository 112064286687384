import { Link } from 'react-router-dom';
import styled from "styled-components";


export const Button = styled(Link)`
    background: ${({ primary }) => (primary ? '#2d3a53' : '#2d3a53')};
    background: #2d3a53;
    white-space: nowrap;
    outline: none;
    border: none;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    padding: ${({big}) => (big ? '16px 40px' : '14px 24px')};
    color: ${({primary}) => (primary ? '#fff' : '#000d1a')};
    font-size: ${({big}) => (big ? '20px' : '14px')};

    &: hover {
        transform: translateY(-2px);
        /*background: #195f83;*/
        background: #000d1a;
        color: #fff;
    }
`;