import React from 'react';
import styled from 'styled-components';
import BannerImg from '../images/banner2.webp';

const BannerDiv = styled.div`
    height: 300px;
    width: 100vw;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        height: 200px;
    }
`;
const BannerTitle = styled.h1`
    display: flex;
    align-items: center;
    padding-top: 50px;
    text-transform: uppercase;
    color: #fff;
`;

const Banner = (props) => {
  return (
    <BannerDiv style={{ backgroundImage:`url(${BannerImg})` }}>
        <BannerTitle>{props.title}</BannerTitle>
    </BannerDiv>
  )
}

export default Banner