import React, {useEffect} from 'react'
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { servicesData } from '../data/ServicesData';
// import { IoMdArrowRoundForward } from 'react-icons/io';
// import { Button } from './Button';
// import { animateScroll as scroll } from 'react-scroll';

import Aos from "aos";
import "aos/dist/aos.css";

const ServiceSection = styled.div`
  padding: 3rem 1.5rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: block;
`;

const CardItem = styled.div`
  border: 1px solid rgba(82, 86, 94, 0.2);
  background: #fff;

  /position: relative;/
  border-radius: 0;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
  transition: 0.3s ease-in-out;
  position: relative;
  
  
  &:hover{
    background: linear-gradient(90deg, #ff87a8 0%, #0019ff 125%);
    color: #FFFFFF;
  }
`;


// const ServiceColumn = styled.div`
//   margin: 20px;
//   /border-radius: 10px;/
//   overflow: hidden;
//   /* box-shadow: 0px 10px 12px rgba(0,0,0,0.1);*/
//   /box-shadow: 2px 4px 10px rgba(0, 0, 0, .25);/
//   box-shadow: 0 3px 6px -4px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
//   transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
//   transition: 0.3s ease-in-out;
//   position: relative;
//   padding: 2rem;
// `;

const ServiceTitle = styled.h4``;

const ServiceText = styled.p``;

// const ServiceLink = styled(Link)``;

// const Arrow = styled(IoMdArrowRoundForward)`
//   margin-left: 0.5rem;
// `;

const Services = () => {

  // const scrollToTop = () => {
  //   scroll.scrollToTop();
  // }

  useEffect(() => {
    Aos.init({duration: 2000});
  }, []);

  return (
    <ServiceSection>
      <div className="container">
        <h2 className='pb-5 text-center'><u>We provide SERVICES like,</u></h2>
        {/* <div className="row">
          {servicesData.map((item, index) => (
            <div className="col-md-4 col-sm-12" key={index}>
              <ServiceColumn>
                <img className='img-fluid' src={item.image} alt={item.alt} />
                <ServiceTitle>{item.title}</ServiceTitle>
                <ServiceText>{item.info}</ServiceText>
                <Button to='/services' onClick={scrollToTop} primary={true}>{item.link}<Arrow /></Button>
              </ServiceColumn>
            </div> 
            background: linear-gradient(180deg, #ff87a8 0%, #0019ff 125%);
    color: #FFFFFF;
          ))}
        </div> */}
        <div className="row">
        {servicesData.map((item, index) => (        
          <div className="col-md-6" data-aos="fade-up">
            <CardItem>
              <div className="row">
                <div className="col-xl-5">
                  <img className='img-fluid' src={item.image} alt="serive" />
                </div>
                <div className="col-xl-7 d-flex flex-row align-items-center">
                  <div className='d-flex flex-column align-items-left p-3'>
                    <ServiceTitle>{item.title}</ServiceTitle>
                    <ServiceText>{item.info}</ServiceText>
                    {/* <Button to='/services' onClick={scrollToTop} primary={true}>{item.link}<Arrow /></Button> */}
                  </div>
                </div>
              </div>
            </CardItem>
          </div>
))} 

        </div>
      </div>
    </ServiceSection>
  )
}

export default Services