import React from 'react'
import About from '../components/About'
import Banner from '../components/Banner'

const AboutPage = () => {
  return (
    <>
    <Banner title='About' />
    <About />
    </>
  )
}

export default AboutPage