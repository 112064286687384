import React from 'react'
import Banner from '../components/Banner'
import MapLocation from '../components/MapLocation'

const ContactPage = () => {
  return (
    <>
    <Banner title='Contact' />
    <MapLocation />

    </>
  )
}

export default ContactPage